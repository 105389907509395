<template>
  <div class="nav">
    <div class="nav-header">
      <div class="nav-header-left">
        <img src="../../assets/logo.png" alt />
      </div>
      <div class="nav-header-right">
        <div
          class="nav-header-right-list"
          v-for="(item, index) in titleList"
          :key="index"
          @click="headerClick(index)"
        >
          <div class="nav-header-right-list-block">
            <div
              class="nav-header-right-list-block-title"
              :style="{
                color: index == titleIndex ? '#2481D7' : '',
                fontWeight: index == titleIndex ? '600' : '',
              }"
            >
              {{ item }}
            </div>
            <div
              class="nav-header-right-list-block-lint"
              v-if="titleIndex == index"
            ></div>
          </div>
        </div>

        <div
          class="right-register"
          @click="registerMet"
          v-if="avatar == ''"
        >
          登录
        </div>
        <div class="right-personal" v-else @click="personageMet">
          <img
            :src="
              avatar == null
                ? require('../../assets/register/personal.png')
                : avatar
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { userInfoGet } from "../../api/index";

export default {
  props: ["tab"],
  data() {
    return {
      // 导航栏标题
      titleList: [
        "首页",
        "产品服务",
        "关于宫匠",
        "资讯动态",
        "风控讲堂",
        "汇智投软件",
        // "帮助中心",
        "加入我们",
      ],
      // 导航栏标题下标
      titleIndex: 0,
      avatar: "",
    };
  },
  created() {
    this.titleIndex = this.tab;

    if (localStorage.getItem("info")) {
      this.avatar = JSON.parse(localStorage.getItem("info")).avatar;
      this.materialMet();
    }
  },
  watch: {
    titleIndex: {
      handler(newValue, oldValue) {
        if (localStorage.getItem("info")) {
          this.titleIndex = 10;
          this.avatar = JSON.parse(localStorage.getItem("info")).avatar;
        }
      },
      deep: true,
    },
  },

  methods: {
    materialMet() {
      let params = {};

      userInfoGet(params).then((res) => {
        if (res.code == 200) {
          this.avatar = res.result.avatar;
        }
      });
    },
    headerClick(index) {
      this.titleIndex = index;
      if (this.titleIndex == 1) {
        this.$router.push({
          path: "/product",
          query: {
            //query是个配置项
          },
        });
      } else if (this.titleIndex == 2) {
        this.$router.push({
          path: "/enterprise",
          query: {
            //企业文化
          },
        });
      } else if (this.titleIndex == 3) {
        this.$router.push({
          path: "/information",
          query: {
            //query是个配置项
          },
        });
      } else if (this.titleIndex == 4) {
        this.$router.push({
          path: "/riskmanagement",
          query: {
            //query是个配置项
          },
        });
      } else if (this.titleIndex == 5) {
        this.$router.push({
          path: "/easygt",
          query: {
            // 加入我们
          },
        });
      } else if (this.titleIndex == 6) {
        this.$router.push({
          path: "/personalus",
          query: {
            // 加入我们
          },
        });
      }  else {
        this.$router.push({
          path: "/",
          query: {
            //query是个配置项
          },
        });
      }
    },

    // 进入登录
    registerMet() {
      this.$router.push({
        path: "/register",
        query: {
          //query是个配置项
        },
      });
    },

    // 返回个人中心
    personageMet() {
      this.$router.replace({
        path: "/personal",
        query: {
          //query是个配置项
        },
      });
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.nav {
  .nav-header {
    height: 88px;
    background: #ffffff;
    // box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.3);
    padding: 0 120px 0 180px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid#E4E4E4;

    .nav-header-left {
      height: 48px;
      cursor: pointer;
    }

    .nav-header-right {
      height: 100%;
      display: flex;
      align-items: center;

      .nav-header-right-list {
        margin-right: 60px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;

        .nav-header-right-list-block {
          height: 40px;
          margin-top: 14px;
          .nav-header-right-list-block-title {
            height: 22px;
            font-size: 16px;
            font-family: PingFangTC-Regular, PingFangTC;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
          }

          .nav-header-right-list-block-lint {
            width: 29px;
            height: 2px;
            background: #2481d7;
            margin: 0 auto;
            margin-top: 6px;
          }
        }
      }

      .nav-header-right-list:last-child {
        margin-right: 0;
      }

      .right-register {
        cursor: pointer;
        width: 56px;
        height: 28px;
        background: #2481d7;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }

      .right-personal {
        width: 40px;
        height: 40px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>