const throttle = (fn, delay) => {
	let oldDate = Date.now();
	return function() {
		let args = arguments;
		let newDate = Date.now();
		let that = this;
		if (newDate - oldDate > delay) {
			fn.apply(that, args);
			//倘若时间差大于延长时间 就更新一次旧时间
			oldDate = Date.now();
		}
	}
}

module.exports = {
	throttle:throttle
}
