var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"nav-header"},[_vm._m(0),_c('div',{staticClass:"nav-header-right"},[_vm._l((_vm.titleList),function(item,index){return _c('div',{key:index,staticClass:"nav-header-right-list",on:{"click":function($event){return _vm.headerClick(index)}}},[_c('div',{staticClass:"nav-header-right-list-block"},[_c('div',{staticClass:"nav-header-right-list-block-title",style:({
              color: index == _vm.titleIndex ? '#2481D7' : '',
              fontWeight: index == _vm.titleIndex ? '600' : '',
            })},[_vm._v(" "+_vm._s(item)+" ")]),(_vm.titleIndex == index)?_c('div',{staticClass:"nav-header-right-list-block-lint"}):_vm._e()])])}),(_vm.avatar == '')?_c('div',{staticClass:"right-register",on:{"click":_vm.registerMet}},[_vm._v(" 登录 ")]):_c('div',{staticClass:"right-personal",on:{"click":_vm.personageMet}},[_c('img',{attrs:{"src":_vm.avatar == null
              ? require('../../assets/register/personal.png')
              : _vm.avatar,"alt":""}})])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-header-left"},[_c('img',{attrs:{"src":require("../../assets/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }