import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)
import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

import dealPrivacy from '../components/common/deal-privacy.vue'
import dealUser from '../components/common/deal-user.vue'


const routes = [
  {
    path: '/', // 首页
    name: 'home',
    component: Home
  }, 
  {
    path: "/pay",
    component: () => import('../components/common/pay.vue'), // 支付
  },
  
  {
    path: "/product",
    component: () => import('../views/product.vue'),//产品服务
  }, {
    path: "/information",
    component: () => import('../views/information.vue'),//企业文化
  }, , {
    path: "/riskmanagement",
    component: () => import('../views/riskmanagement.vue'),//风控
  }, {
    path: "/enterprise",
    component: () => import('../views/enterprise.vue'),//资讯
  },
  {
    path: "/personalus",
    component: () => import('../views/personalus.vue'),// 加入我们
  },
  {
    path: "/easygt",
    component: () => import('../views/easyget.vue'),// 易X得软件
  },
  {
    path: "/register",
    component: () => import('../views/register/index.vue'),// 等
  },
  {
    path: "/register-forget",
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/register/forget.vue'),// 等
  },
  //-----------商品区域
  {
    path: "/merchandise",
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/merchandise/index.vue'),// 等
  },
  {
    path: "/purchase",
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import('../views/merchandise/purchase.vue'),// 等
  },

  // 个人中心区域
  {
    path: "/personal",
    name: "personal",
    redirect: "/personal/material",
    component: () => import(/* webpackChunkName: "home" */ "../views/personal/index.vue"),
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    children: [
      // 我的资料
      {
        path: "material",
        name: "material",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personal/material.vue"),
      },
      // 我的订单
      {
        path: "order",
        name: "order",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personal/order.vue"),
    
      },
    ]
  },

  //-------------
  {
    path: '/dealPrivacy',
    name: 'dealPrivacy',
    component: dealPrivacy
  },
  {
    path: '/dealUser',
    name: 'dealUser',
    component: dealUser
  },
  // {
  //   path: "/personal",
  //   redirect: "/personal/material",
  //   component: () => import('../views/personal/material.vue'),// 等
  //   children: [
  //     // 我的资料
  //     {
  //       path: "material",
  //       name: "material",
  //       component: () => import(/* webpackChunkName: "home" */ "../views/personal/material.vue"),
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/register"
      });
    }
  } else {
    next()
  }
})

export default router

// 解决 vue-router 升级导致的 Uncaught(in promise)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}



Vue.use(VueRouter)
